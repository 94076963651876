<template>
  <div class="invest-relatives" :class="{skeleton:!state.project.loaded}">
    <div class="title font-md">
      <b>투자 정보</b>
    </div>
    <div class="desc font-sm">회원님께서 인증받은 투자가능 프로젝트 목록입니다.</div>
    <div class="content">
      <ul class="tight font-sm">
        <li v-for="(p, idx) in state.project.list" :key="idx">
          <div class="wrapper">
            <div>
              <b>회사명: </b>
              <span>{{ p.corporateName }}</span>
            </div>
            <div class="pt-2 pb-2">
              <b>펀딩명: </b>
              <span>{{ p.projectName }}</span>
            </div>
            <div>
              <b>펀딩 기간(투자 가능 기간): </b>
              <span>{{ p.fundingStartDate }} ~ {{ p.fundingEndDate }}</span>
            </div>
            <router-link class="btn btn-light font-sm" :to="`/invest/${p.investSeq}`">프로젝트 보기</router-link>
          </div>
        </li>
      </ul>
      <div class="more" v-if="state.project.list.length < state.project.count">
        <button class="font-sm btn btn-light" @click="loadRelatives()" :disabled="!state.project.loaded">더 보기</button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import MemberPicture from "@/components/MemberPicture";
import NoData from "@/components/NoData";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "modalInvestRelatives";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MemberPicture, NoData},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});

      state.project.loaded = false;
      http.get("/api/investor/projects", state.project.args).then(({data}) => {
        state.project.loaded = true;
        state.project.count = data.body.paginationInfo.totalRecordCount;
        state.project.list = data.body.list;
      });
    });

    const state = reactive({
      project: {
        loaded: false,
        args: {
          pageIndex: 1,
          pageSize: 5,
          pageUnit: 5,
          mode: "view"
        },
        count: 0,
        list: []
      }
    });

    const loadRelatives = (init) => {
      state.project.loaded = false;

      if (init) {
        http.get("/api/investor/projects", state.project.args).then(({data}) => {
          state.project.loaded = true;
          state.project.count = data.body.paginationInfo.totalRecordCount;
          state.project.list = data.body.list;
        });
      } else {
        state.project.args.pageIndex += 1;
        http.get("/api/investor/projects", state.project.args).then(({data}) => {
          state.project.loaded = true;
          state.project.list.push(...data.body.list);
        });
      }
    };

    return {component, state, loadRelatives};
  }
});
</script>

<style lang="scss" scoped>
.invest-relatives {
  background: #fff;
  padding: $px25;

  .title {
    margin-bottom: $px9;
  }

  .desc {
    margin-bottom: $px25;
    color: #666;
  }

  .content {
    > ul > li {
      padding: $px20;
      border: $px1 solid #eee;

      > .wrapper {
        position: relative;
        padding-right: $px120;

        > .btn {
          position: absolute;
          top: 50%;
          right: $px5;
          padding: $px15 $px15;
          transform: translateY(-50%);
        }
      }

      &:not(:last-child) {
        margin-bottom: $px15;
      }
    }

    .more {
      padding-top: $px20;
      text-align: center;

      .btn {
        padding: $px15 $px25;
      }
    }
  }

  &.skeleton {
  }

  @media(max-width: 767px) {
    .content > ul > li > .wrapper {
      padding-right: 0;

      > .btn {
        position: static;
        transform: none;
        margin-top: $px15;
      }
    }
  }
}
</style>